@media screen and (min-width: 699px) {
  .social-collapse {
    display: none !important;
  }
}

@media screen and (max-width: 700px) {
  .no-social-collapse {
    display: none !important;
  }
}

html {
  height: 100%;
}

@media screen and (max-width: 50rem) {
  html {
    font-size: 80%;
  }
}

body {
  height: 100%;
  min-height: 750px;
}

@media screen and (max-width: 30rem) {
  body {
    min-width: 200px;
  }
}

#react-app-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  background: #65c8cd;
  color: #feffff;
  font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif;
}

#react-app-root header,
#react-app-root footer {
  width: 100%;
}

#react-app-root header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#react-app-root header .main-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 73.1875rem;
  padding: 2rem 10rem;
}

@media screen and (max-width: 30rem) {
  #react-app-root header .main-bar {
    padding: 4vh 7vw;
  }

  #react-app-root header .main-bar .contact .phone {
    font-size: 7vw;
  }
}

#react-app-root header .main-bar .logo {
  width: 243px;
  max-width: 65vw;
}

#react-app-root footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5.5rem 0 3.5rem 0;
}

#react-app-root .page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

#react-app-root .page-container .page {
  margin: auto;
  max-width: 50rem;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .page {
    width: auto;
  }
}

#react-app-root .page-container .page form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#react-app-root h1,
#react-app-root h2,
#react-app-root h3 {
  color: #612e8a;
  font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: lowercase;
  text-align: center;
  user-select: none;
}

#react-app-root h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 30rem) {
  #react-app-root h1 {
    font-size: 8vw;
  }
}

#react-app-root h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 30rem) {
  #react-app-root h2 {
    font-size: 5.5vw;
  }
}

#react-app-root p {
  font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif;
  max-width: 34.375rem;
  text-align: center;
  margin: 0 auto;
  line-height: 1.5rem;
}

#react-app-root p.lead {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.8px;
}

#react-app-root ::selection {
  color: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif;
}

#react-app-root fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

#react-app-root ::placeholder {
  font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif;
}

#react-app-root .btn {
  position: relative;
  padding: 1rem 0;
  width: 18rem;
  color: #feffff;
  background: #f26060;
  border: 1px solid rgba(248,248,249,0.46);
  border-radius: 19px;
  box-shadow: #f2d0d0 0 6px 0 0;
  cursor: pointer;
  outline: 0;
  font-weight: 700;
  transition: filter 300ms ease;
}

#react-app-root .btn:after {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: '\F054';
  position: absolute;
  right: 1rem;
  font-weight: 700;
  transition: opacity 300ms ease;
}

#react-app-root .btn:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 30px;
  top: -1px;
}

#react-app-root .btn:hover {
  box-shadow: #f2d0d0 0 3px 0 0;
  top: 3px;
}

#react-app-root .btn:hover:before {
  top: -4px;
}

#react-app-root .btn:active {
  box-shadow: #f2d0d0 0 0px 0 0;
  top: 6px;
}

#react-app-root .btn:active:before {
  top: -7px;
}

#react-app-root .btn[disabled] {
  cursor: wait;
  top: 0 !important;
  background: #612e8a;
  box-shadow: #d6d6d6 0 6px 0 0;
}

#react-app-root .btn[disabled]:before {
  top: -1px !important;
}

#react-app-root .btn.submitting:after {
  opacity: 0;
}

@media screen and (max-width: 50rem) {
  #react-app-root .btn {
    padding: 1.4rem 0;
  }
}

#react-app-root .btn--small {
  padding: .4rem 0;
  width: 13rem;
}

#react-app-root .btn--small:after {
  display: none;
}

#react-app-root .Select .Select-control {
  border: 0;
  margin: 0;
  outline: 0;
  box-shadow: none;
  line-height: 0;
  height: auto;
}

#react-app-root .Select .Select-placeholder {
  padding-left: 0;
  padding-right: 0;
  user-select: none;
}

#react-app-root .Select .Select-input {
  height: auto;
}

#react-app-root .Select .Select-placeholder,
#react-app-root .Select .Select--single>.Select-control .Select-value {
  font-size: 1rem;
  line-height: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 0rem;
  overflow: visible;
}

#react-app-root .Select .Select-menu-outer {
  margin-top: -1px;
  border-color: #e8e8e8;
  left: -1px;
  right: 0;
  box-sizing: content-box;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  border-color: inherit;
}

#react-app-root .Select .Select-menu {
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
}

#react-app-root .Select .Select-placeholder {
  color: #848484;
}

#react-app-root .Select .Select-multi-value-wrapper {
  display: inline-block;
  line-height: 0;
  height: auto;
}

#react-app-root .Select .Select-arrow-zone {
  padding: 0;
  text-align: right;
  width: auto;
}

#react-app-root .Select .Select-input {
  padding: 0;
}

#react-app-root .Select .Select-input input {
  padding: 0;
}

#react-app-root .Select .Select-input input:focus {
  box-shadow: none;
}

#react-app-root .Select .Select-value {
  line-height: 1rem;
  padding: 0;
}

#react-app-root .Select .Select-option.is-selected {
  background: #f8f8f9;
}

#react-app-root .Select .Select-option.is-focused {
  background: #e7f5f7;
}

#react-app-root .Select.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#react-app-root .Select.is-disabled {
  background: #f2f2f2 !important;
}

#react-app-root .Select.is-disabled .Select-control .Select-placeholder {
  opacity: 0.75;
}

#react-app-root .Select.is-focused {
  box-shadow: #49b2b7 0px 0px 6px 0px;
}

#react-app-root .Select.is-focused .Select-menu-outer {
  box-shadow: #49b2b7 0px 2px 6px -1px;
  box-shadow: #49b2b7 0px 2px 7px -1px;
}

#react-app-root .fade-exit-active {
  transition: opacity ease 300ms;
  opacity: 0;
}

#react-app-root .fade-enter-active {
  display: none !important;
}

#react-app-root header .contact,
#react-app-root footer .contact {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}

#react-app-root header .contact a,
#react-app-root footer .contact a {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-right: 2rem;
}

#react-app-root header .contact a.phone,
#react-app-root footer .contact a.phone {
  margin-right: 1.5rem;
}

#react-app-root header .contact a.phone .big,
#react-app-root footer .contact a.phone .big {
  font-size: 1.1em;
}

#react-app-root header .contact a.phone i,
#react-app-root footer .contact a.phone i {
  vertical-align: top;
  display: block;
}

#react-app-root header .contact a.facebook,
#react-app-root footer .contact a.facebook {
  font-size: 0.9em;
}

#react-app-root header .contact a:last-child,
#react-app-root footer .contact a:last-child {
  margin-right: 0;
}

#react-app-root header .main-bar .logo,
#react-app-root header .main-bar .facebook,
#react-app-root header .main-bar .twitter {
  user-select: none;
}

@media screen and (max-width: 43.75rem) {
  #react-app-root header .main-bar .contact {
    align-items: start;
  }

  #react-app-root header .main-bar .contact .phone {
    font-size: 3.3rem;
    margin-right: 0;
  }

  #react-app-root header .main-bar .facebook,
  #react-app-root header .main-bar .instagram {
    display: none;
  }
}

#react-app-root header .contact .how-it-works-desktop {
  display: none;
}

@media screen and (min-width: 43.75rem) {
  #react-app-root header .contact .how-it-works-desktop {
    display: inline;
  }
}

@media screen and (max-width: 43.75rem) {
  #react-app-root footer {
    padding: 2.5rem 0;
  }

  #react-app-root footer .contact a {
    margin-right: 0;
  }

  #react-app-root footer .contact a.phone {
    margin-right: 0;
  }

  #react-app-root footer .contact a.phone .big {
    font-size: 1.05em;
  }
}

@media screen and (max-width: 30rem) {
  #react-app-root footer {
    padding: 3.5rem 0;
  }
}

#react-app-root footer .contact {
  margin-bottom: 2rem;
  max-width: 13rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#react-app-root footer .contact>* {
  width: 100%;
  text-align: center;
}

#react-app-root .page-container {
  position: relative;
  background: linear-gradient(#65c8cd, #abdee1 80%, #abdee1);
}

#react-app-root .page-container .background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#react-app-root .page-container .background .cloud,
#react-app-root .page-container .background #environment,
#react-app-root .page-container .background #car {
  background: rgba(0,0,0,0) center bottom no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: background-size ease 300ms;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .background .cloud,
  #react-app-root .page-container .background #environment,
  #react-app-root .page-container .background #car {
    background-size: 160%;
  }
}

@media screen and (max-width: 30rem) {
  #react-app-root .page-container .background #car {
    background-size: 270%;
  }
}

@keyframes car-bounce-0 {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-bounce-1 {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-bounce-2 {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-bounce-3 {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-bounce-4 {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-bounce-5 {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-bounce-6 {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-bounce-loading {
  from {
    transform: translate(0rem, 0rem);
  }

  to {
    transform: translate(0rem, -1rem);
  }
}

@keyframes car-drive-off {
  from {
    transform: translate(0%, 0);
  }

  to {
    transform: translate(70%, 0);
  }
}

#react-app-root .page-container .background #car {
  background-image: url(/src/assets/images/car.ab02b694ef05b0aa28f84a5b64e63373.svg);
  z-index: 25;
}

#react-app-root .page-container .background #car.bounce-0 {
  animation-name: car-bounce-0;
  animation-duration: 200ms;
  animation-iteration-count: 4;
  animation-timing-function: ease;
  animation-direction: alternate;
}

#react-app-root .page-container .background #car.bounce-1 {
  animation-name: car-bounce-1;
  animation-duration: 200ms;
  animation-iteration-count: 4;
  animation-timing-function: ease;
  animation-direction: alternate;
}

#react-app-root .page-container .background #car.bounce-2 {
  animation-name: car-bounce-2;
  animation-duration: 200ms;
  animation-iteration-count: 4;
  animation-timing-function: ease;
  animation-direction: alternate;
}

#react-app-root .page-container .background #car.bounce-3 {
  animation-name: car-bounce-3;
  animation-duration: 200ms;
  animation-iteration-count: 4;
  animation-timing-function: ease;
  animation-direction: alternate;
}

#react-app-root .page-container .background #car.bounce-4 {
  animation-name: car-bounce-4;
  animation-duration: 200ms;
  animation-iteration-count: 4;
  animation-timing-function: ease;
  animation-direction: alternate;
}

#react-app-root .page-container .background #car.bounce-5 {
  animation-name: car-bounce-5;
  animation-duration: 200ms;
  animation-iteration-count: 4;
  animation-timing-function: ease;
  animation-direction: alternate;
}

#react-app-root .page-container .background #car.bounce-6 {
  animation-name: car-bounce-6;
  animation-duration: 200ms;
  animation-iteration-count: 4;
  animation-timing-function: ease;
  animation-direction: alternate;
}

#react-app-root .page-container .background #car.driven-off {
  animation-name: car-drive-off;
  animation-delay: 1s;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

#react-app-root .page-container .background #environment {
  background-image: url(/src/assets/images/environment.de123916e8d93526afd90ecd7e9f34cb.svg);
  z-index: 20;
}

@keyframes cloud-1-keyframes {
  from {
    transform: translate(0px, 0);
  }

  to {
    transform: translate(-233px, 0);
  }
}

#react-app-root .page-container .background #cloud-1 {
  background-image: url(/src/assets/images/cloud-1.a4d2c99398d93872026e2839bc042bfa.svg);
  animation-name: cloud-1-keyframes;
  animation-duration: 33.28571s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes cloud-2-keyframes {
  from {
    transform: translate(0px, 0);
  }

  to {
    transform: translate(-123px, 0);
  }
}

#react-app-root .page-container .background #cloud-2 {
  background-image: url(/src/assets/images/cloud-2.637dffb8df013232d962753d0a9ef464.svg);
  animation-name: cloud-2-keyframes;
  animation-duration: 17.57143s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes cloud-3-keyframes {
  from {
    transform: translate(0px, 0);
  }

  to {
    transform: translate(157px, 0);
  }
}

#react-app-root .page-container .background #cloud-3 {
  background-image: url(/src/assets/images/cloud-3.2569c87472c5dcff2563a060e9883f79.svg);
  animation-name: cloud-3-keyframes;
  animation-duration: 22.42857s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes cloud-4-keyframes {
  from {
    transform: translate(0px, 0);
  }

  to {
    transform: translate(-246px, 0);
  }
}

#react-app-root .page-container .background #cloud-4 {
  background-image: url(/src/assets/images/cloud-4.31592508767f037ea7aa3b1a38cdefbc.svg);
  animation-name: cloud-4-keyframes;
  animation-duration: 35.14286s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes cloud-5-keyframes {
  from {
    transform: translate(0px, 0);
  }

  to {
    transform: translate(192px, 0);
  }
}

#react-app-root .page-container .background #cloud-5 {
  background-image: url(/src/assets/images/cloud-5.30048fda5e65956d984e1471e19e359d.svg);
  animation-name: cloud-5-keyframes;
  animation-duration: 27.42857s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes cloud-6-keyframes {
  from {
    transform: translate(0px, 0);
  }

  to {
    transform: translate(-221px, 0);
  }
}

#react-app-root .page-container .background #cloud-6 {
  background-image: url(/src/assets/images/cloud-6.5eb6d0d87328098bb36abb85bff5008a.svg);
  animation-name: cloud-6-keyframes;
  animation-duration: 31.57143s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

#react-app-root .page-container .loading+.background #car {
  animation-name: car-bounce-loading;
  animation-duration: 200ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: alternate;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

#react-app-root .page-container .page {
  z-index: 50;
}

#react-app-root .page-container .clear-page {
  margin-top: 2.5rem;
  opacity: 1;
  transition: opacity ease 1s;
}

#react-app-root .page-container .clear-page p {
  margin-bottom: 2.5rem;
}

#react-app-root .page-container .clear-page.loading {
  opacity: 0;
}

#react-app-root .page-container .clear-page .know-more {
  display: block;
  color: #feffff;
  margin-top: -2rem;
  margin-bottom: 3rem;
  text-align: center;
}

#react-app-root .page-container .clear-page .first-step-choice {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1rem;
  user-select: none;
}

@media screen and (max-width: 30rem) {
  #react-app-root .page-container .clear-page .first-step-choice {
    flex-direction: column;
  }
}

#react-app-root .page-container .clear-page .first-step-choice .or {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input {
  display: flex;
  position: relative;
  padding: 1rem 0;
  width: 18rem;
  color: #feffff;
  background: #f26060;
  border: 1px solid rgba(248,248,249,0.46);
  border-radius: 19px;
  box-shadow: #f2d0d0 0 6px 0 0;
  cursor: pointer;
  outline: 0;
  background: #fff;
  border-color: #e8e8e8;
  box-shadow: #49b2b7 0px 5px 0 0;
  color: #000;
  padding: 0 0 0 1rem;
  width: 14.25rem;
  max-width: 228px;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input input:focus {
  box-shadow: none;
}

@media screen and (max-width: 30rem) {
  #react-app-root .page-container .clear-page .first-step-choice .splash-input {
    width: 100%;
  }
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input ::placeholder,
#react-app-root .page-container .clear-page .first-step-choice .splash-input .Select-placeholder {
  color: #848484;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input .input {
  width: 80%;
  cursor: text;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input .btn-select {
  background: url(/src/assets/images/input-select-arrow-2x.d3a37ddac4fe925a5ac217b40a81a8e4.png) no-repeat center center;
  background-size: 25%;
  border: 0;
  cursor: pointer;
  user-select: none;
  width: 20%;
  z-index: 100;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input:focus-within {
  box-shadow: #49b2b7 0px 5px 0 0, #49b2b7 0px 0px 6px 1px;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input button {
  outline: 0;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select {
  position: static;
  padding: 1rem 0;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select.is-focused {
  box-shadow: none;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select-placeholder,
#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select--single>.Select-control .Select-value {
  font-size: 1rem;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select-menu-outer {
  border-color: #e8e8e8;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select-option {
  padding: 0.6rem 1rem;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select-value-label {
  position: relative;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-state .Select-clear-zone {
  display: none;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-rego input {
  outline: 0;
  border: 0;
  padding: 1rem 0rem 1rem 0rem;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .clear-page .first-step-choice .splash-input-rego input {
    padding: 1.3rem 0rem;
  }
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-rego button {
  outline: 0;
  border: 0;
}

#react-app-root .page-container .clear-page .first-step-choice .splash-input-rego button img {
  width: 11px;
}

#react-app-root .page-container .clear-page .temp-button {
  margin-top: 2em;
  position: relative;
  padding: 1rem 0;
  width: 18rem;
  color: #feffff;
  background: #f26060;
  border: 1px solid rgba(248,248,249,0.46);
  border-radius: 19px;
  box-shadow: #f2d0d0 0 6px 0 0;
  cursor: pointer;
  outline: 0;
  font-weight: 700;
  transition: filter 300ms ease;
}

#react-app-root .page-container .clear-page .temp-button:after {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: '\F054';
  position: absolute;
  right: 1rem;
  font-weight: 700;
  transition: opacity 300ms ease;
}

#react-app-root .page-container .clear-page .temp-button:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 30px;
  top: -1px;
}

#react-app-root .page-container .clear-page .temp-button:hover {
  box-shadow: #f2d0d0 0 3px 0 0;
  top: 3px;
}

#react-app-root .page-container .clear-page .temp-button:hover:before {
  top: -4px;
}

#react-app-root .page-container .clear-page .temp-button:active {
  box-shadow: #f2d0d0 0 0px 0 0;
  top: 6px;
}

#react-app-root .page-container .clear-page .temp-button:active:before {
  top: -7px;
}

#react-app-root .page-container .clear-page .temp-button[disabled] {
  cursor: wait;
  top: 0 !important;
  background: #612e8a;
  box-shadow: #d6d6d6 0 6px 0 0;
}

#react-app-root .page-container .clear-page .temp-button[disabled]:before {
  top: -1px !important;
}

#react-app-root .page-container .clear-page .temp-button.submitting:after {
  opacity: 0;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .clear-page .temp-button {
    padding: 1.4rem 0;
  }
}

#react-app-root .page-container .white-page {
  background: #fff;
  color: #848484;
  padding: 3rem 5rem;
  box-shadow: #77777778 0px 0px 27px 1px;
  transition: height ease 0.5s;
  margin-top: 0;
  margin-bottom: -2rem;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .white-page {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 30rem) {
  #react-app-root .page-container .white-page {
    padding: 2rem 2rem;
  }
}

#react-app-root .page-container .white-page h1,
#react-app-root .page-container .white-page h2,
#react-app-root .page-container .white-page h3 {
  margin-top: 0;
}

#react-app-root .page-container .white-page a {
  color: #612e8a;
}

#react-app-root .page-container .white-page .text-center {
  text-align: center;
}

#react-app-root .page-container .white-page .subtitle {
  margin-bottom: 2rem;
  margin-top: -2rem;
  font-size: 0.95rem;
}

#react-app-root .page-container .white-page input[type='text'],
#react-app-root .page-container .white-page input[type='tel'],
#react-app-root .page-container .white-page input[type='email'],
#react-app-root .page-container .white-page textbox,
#react-app-root .page-container .white-page .Select {
  color: #222;
  background: #e7f5f7;
  border: 1px solid #f8f8f9;
  padding: 1rem 2rem;
  border-radius: 19px;
  border: #e9e9e9 1px solid;
  outline: 0;
}

#react-app-root .page-container .white-page input[type='text']:focus,
#react-app-root .page-container .white-page input[type='tel']:focus,
#react-app-root .page-container .white-page input[type='email']:focus,
#react-app-root .page-container .white-page textbox:focus,
#react-app-root .page-container .white-page .Select:focus {
  box-shadow: #49b2b7 0px 0px 6px 0px;
}

#react-app-root .page-container .white-page .text-field {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 1.7rem;
}

#react-app-root .page-container .white-page .text-field label {
  position: absolute;
  left: 2rem;
  top: -1rem;
  opacity: 0;
  font-size: 0.7rem;
  transition: opacity ease 200ms;
}

#react-app-root .page-container .white-page .text-field.placeholder-shown label {
  opacity: 0;
}

#react-app-root .page-container .white-page .text-field input[type='text'] {
  margin-bottom: 0;
  transition: border-color ease 300ms;
}

#react-app-root .page-container .white-page .text-field.touched.known-validation input,
#react-app-root .page-container .white-page .text-field.touched.known-validation .Select:not([disabled]) {
  border-color: #b5e5b2;
}

#react-app-root .page-container .white-page .text-field .validation-error-message {
  opacity: 0;
  transition: opacity ease 300ms;
}

#react-app-root .page-container .white-page .text-field.validation-error.known-validation input,
#react-app-root .page-container .white-page .text-field.validation-error.known-validation .Select:not([disabled]) {
  border-color: #f26060;
}

#react-app-root .page-container .white-page .text-field.validation-error.known-validation .validation-error-message {
  position: absolute;
  bottom: 0.5rem;
  left: 2rem;
  opacity: 1;
  font-size: 0.8rem;
  color: #f26060;
}

#react-app-root .page-container .white-page .Select {
  position: relative;
}

#react-app-root .page-container .white-page .Select.is-focused .Select-control {
  box-shadow: none;
}

#react-app-root .page-container .white-page .Select.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#react-app-root .page-container .white-page .Select-control {
  line-height: 1rem;
  background: inherit;
  height: auto;
  border-radius: 0;
}

#react-app-root .page-container .white-page .Select-control:hover {
  box-shadow: none;
}

#react-app-root .page-container .white-page .Select-menu-outer {
  border-color: inherit;
}

#react-app-root .page-container .white-page .Select-option {
  padding: 0.8rem 2rem;
}

#react-app-root .page-container .white-page .radio-group-field {
  position: relative;
  margin-bottom: 1rem;
  text-align: center;
  width: 50%;
  padding: 5px;
}

#react-app-root .page-container .white-page .radio-group-field legend {
  font-weight: bold;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem auto;
  width: 56%;
  max-width: 10rem;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio label {
  cursor: pointer;
  user-select: none;
  padding-right: 1.8rem;
  z-index: 2;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio input {
  margin: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  cursor: pointer;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio .radio-mark-container {
  position: absolute;
  right: 0;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio .radio-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  background: #e7f5f7;
  border: 1px solid #f8f8f9;
  padding: 1rem 2rem;
  border-radius: 19px;
  border: #e9e9e9 1px solid;
  outline: 0;
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1rem;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio .radio-mark:focus {
  box-shadow: #49b2b7 0px 0px 6px 0px;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio .radio-mark:after {
  display: none;
  content: ' ';
  width: .5rem;
  height: .5rem;
  background: #848484;
  border-radius: 10000px;
}

#react-app-root .page-container .white-page .radio-group-field .radio-group-container .radio-group-radio input:checked+.radio-mark-container .radio-mark:after {
  display: inline-block;
}

#react-app-root .page-container .white-page .radio-group-field .validation-error-message {
  bottom: 0.5rem;
  left: 2rem;
  opacity: 1;
  font-size: 0.8rem;
  color: #f26060;
}

#react-app-root .page-container .white-page .checkbox-field {
  margin-bottom: 5px;
}

#react-app-root .page-container .white-page .checkbox-field label {
  cursor: pointer;
}

#react-app-root .page-container .white-page .checkbox-field label input {
  clip: rect(0 0 0 0);
  height: 1px;
  position: absolute;
  width: 1px;
}

#react-app-root .page-container .white-page .checkbox-field label .checkbox-mark-container {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

#react-app-root .page-container .white-page .checkbox-field label .checkbox-mark-container .checkbox-mark {
  display: flex;
  background: #e7f5f7;
  border: #e9e9e9 1px solid;
  width: 1.25rem;
  border-color: #53babf;
  border-radius: 5px;
}

#react-app-root .page-container .white-page .checkbox-field label .checkbox-mark-container svg {
  fill: none;
  stroke: #53babf;
  stroke-width: 3px;
  width: 100%;
}

#react-app-root .page-container .white-page .privacy-policy {
  text-align: center;
  width: 100%;
}

#react-app-root .page-container .white-page .privacy-policy label {
  margin-left: 0.5rem;
}

#react-app-root .page-container .white-page textarea {
  color: #222;
  background: #e7f5f7;
  border: 1px solid #f8f8f9;
  padding: 1rem 2rem;
  border-radius: 19px;
  border: #e9e9e9 1px solid;
  outline: 0;
  min-height: 4rem;
  font-family: Arial;
  font-size: 12px;
}

#react-app-root .page-container .white-page textarea:focus {
  box-shadow: #49b2b7 0px 0px 6px 0px;
}

#react-app-root .page-container .white-page .form-part.image-upload {
  flex-direction: column;
}

#react-app-root .page-container .white-page .image-upload__btn-container {
  text-align: center;
  margin-bottom: 40px;
}

#react-app-root .page-container .white-page .image-field-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}

#react-app-root .page-container .white-page .image-field {
  display: inline-block;
  position: relative;
  width: 23.5%;
  background: #e7e8e9;
  transition: background ease 300ms;
  margin: 0 1% 1rem 0;
}

@media screen and (max-width: 30rem) {
  #react-app-root .page-container .white-page .image-field {
    width: 49%;
  }
}

#react-app-root .page-container .white-page .image-field:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

#react-app-root .page-container .white-page .image-field input {
  width: 100%;
  visibility: hidden;
}

#react-app-root .page-container .white-page .image-field .upload-click-area {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
}

#react-app-root .page-container .white-page .image-field .image-preview {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#react-app-root .page-container .white-page .image-field .image-preview img {
  position: absolute;
  width: 150%;
}

#react-app-root .page-container .white-page .image-field .image-remove {
  position: absolute;
  display: none;
}

#react-app-root .page-container .white-page .image-field.has-image {
  background: #6d6d6d;
}

#react-app-root .page-container .white-page .image-field.has-image .upload-click-area {
  top: initial;
  height: initial;
  bottom: 0;
  width: 100%;
  padding: 0.2rem 0;
  background: rgba(231,232,233,0.9);
}

#react-app-root .page-container .white-page .image-field .uploading-message {
  display: none;
  color: #333;
  height: 100%;
  width: 100%;
  top: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}

#react-app-root .page-container .white-page .image-field .error-message {
  display: none;
  position: absolute;
  width: 100%;
  top: 0;
  text-align: center;
  padding-top: 1rem;
  color: #ff5722;
}

#react-app-root .page-container .white-page .image-field.uploading {
  background: #f8d396;
}

#react-app-root .page-container .white-page .image-field.uploading .uploading-message {
  display: flex;
}

#react-app-root .page-container .white-page .image-field.uploading .image-upload-controls {
  display: none;
}

#react-app-root .page-container .white-page .image-field.error {
  background: #c7c7c7;
}

#react-app-root .page-container .white-page .image-field.error .error-message {
  display: block;
}

#react-app-root .page-container .white-page .btn-primary {
  position: relative;
  padding: 1rem 0;
  width: 18rem;
  color: #feffff;
  background: #f26060;
  border: 1px solid rgba(248,248,249,0.46);
  border-radius: 19px;
  box-shadow: #f2d0d0 0 6px 0 0;
  cursor: pointer;
  outline: 0;
  font-weight: 700;
  transition: filter 300ms ease;
  max-width: 100%;
  margin-top: 1rem;
}

#react-app-root .page-container .white-page .btn-primary:after {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: '\F054';
  position: absolute;
  right: 1rem;
  font-weight: 700;
  transition: opacity 300ms ease;
}

#react-app-root .page-container .white-page .btn-primary:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 30px;
  top: -1px;
}

#react-app-root .page-container .white-page .btn-primary:hover {
  box-shadow: #f2d0d0 0 3px 0 0;
  top: 3px;
}

#react-app-root .page-container .white-page .btn-primary:hover:before {
  top: -4px;
}

#react-app-root .page-container .white-page .btn-primary:active {
  box-shadow: #f2d0d0 0 0px 0 0;
  top: 6px;
}

#react-app-root .page-container .white-page .btn-primary:active:before {
  top: -7px;
}

#react-app-root .page-container .white-page .btn-primary[disabled] {
  cursor: wait;
  top: 0 !important;
  background: #612e8a;
  box-shadow: #d6d6d6 0 6px 0 0;
}

#react-app-root .page-container .white-page .btn-primary[disabled]:before {
  top: -1px !important;
}

#react-app-root .page-container .white-page .btn-primary.submitting:after {
  opacity: 0;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .white-page .btn-primary {
    padding: 1.4rem 0;
  }
}

#react-app-root .page-container .white-page .form-part {
  display: flex;
}

#react-app-root .page-container .white-page .form-part.two-col {
  width: 35rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 30rem) {
  #react-app-root .page-container .white-page .form-part.two-col {
    width: 48%;
    justify-content: center;
  }
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .white-page .form-part.two-col {
    width: 100%;
  }
}

#react-app-root .page-container .white-page .form-part.two-col .text-field {
  width: 16.875rem;
  max-width: 270px;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .white-page .form-part.two-col .text-field {
    width: 48%;
  }
}

@media screen and (max-width: 30rem) {
  #react-app-root .page-container .white-page .form-part.two-col .text-field {
    width: 100%;
  }
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .white-page .form-part.two-col .radio-group-field {
    width: 100%;
  }
}

#react-app-root .page-container .white-page .form-part.two-col .checkbox-field {
  width: 50%;
}

@media screen and (max-width: 50rem) {
  #react-app-root .page-container .white-page .form-part.two-col .checkbox-field {
    width: 100%;
  }
}

#react-app-root .page-container .white-page #not-your-car {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}

#react-app-root .page-container .about p {
  text-align: left;
  margin-left: 0;
}

#react-app-root .page-container .about ol {
  list-style-position: inside;
  padding-left: 1rem;
}

#react-app-root .page-container .about .outer-button {
  text-align: center;
}

#react-app-root .how-it-works p {
  margin-bottom: 1.5rem;
}

#react-app-root .how-it-works .step {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 30rem) {
  #react-app-root .how-it-works .step {
    flex-direction: column;
  }
}

#react-app-root .how-it-works .step__title {
  position: relative;
  padding: 1rem 0;
  width: 18rem;
  color: #feffff;
  background: #f26060;
  border: 1px solid rgba(248,248,249,0.46);
  border-radius: 19px;
  box-shadow: #f2d0d0 0 6px 0 0;
  cursor: pointer;
  outline: 0;
  cursor: default;
  text-align: center;
  align-self: flex-start;
  width: 20%;
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 50rem) {
  #react-app-root .how-it-works .step__title {
    width: 35%;
  }
}

@media screen and (max-width: 30rem) {
  #react-app-root .how-it-works .step__title {
    width: 100%;
    margin-bottom: 20px;
  }
}

#react-app-root .how-it-works .step__explanation {
  text-align: left;
  padding-left: 30px;
  width: 80%;
}

@media screen and (max-width: 50rem) {
  #react-app-root .how-it-works .step__explanation {
    width: 65%;
    padding-left: 20px;
  }
}

@media screen and (max-width: 30rem) {
  #react-app-root .how-it-works .step__explanation {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 0;
  }
}

#react-app-root .how-it-works .step__subtitle {
  text-align: inherit;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}

#react-app-root .how-it-works .step__content {
  text-align: inherit;
  margin-bottom: 2.5rem;
}

#react-app-root .image-canvas-description-container h2 {
  margin-bottom: 0;
}

#react-app-root .image-canvas-description-container .draw-images-label-container {
  font-size: 0.95rem;
  text-align: center;
}

#react-app-root .images-canvas-container {
  margin-bottom: 10px;
}

#react-app-root .images-canvas-container .image-canvas-container {
  padding: 5px;
  margin-bottom: 10px;
}

#react-app-root .images-canvas-container .image-canvas-container canvas {
  touch-action: none;
  margin-bottom: 10px;
}

#react-app-root .images-canvas-container .image-canvas-container .controls .text-field {
  padding-bottom: 0px;
}

@media screen and (min-width: 50rem) {
  #react-app-root .images-canvas-container .image-canvas-container .controls .buttons-container {
    display: flex;
    justify-content: space-between;
  }
}

#react-app-root .images-canvas-container .image-canvas-container .controls .buttons-container button {
  max-width: 49%;
}

#react-app-root .images-canvas-container .image-canvas-container .controls .buttons-container button:before {
  content: '';
}

.easi-logo {
  max-width: 90px;
  width: 100%;
  margin-bottom: 5px;
}